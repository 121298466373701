.login {
  width: 245px;
  .root {
    display: flex;
    flex-direction: column;
  }
  .first {
    margin-bottom: 1rem;
  }
  ::placeholder {
    color: white;
  }
  .loginSection {
    display: flex;
    justify-content: flex-end;

    .loginBtn {
      font-size: 20px;
      color: white;
      display: flex;
      margin-top: 0.6rem;
      font-weight: 300;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
  .error {
    color: red;
    // width: 50%;
  }
}
