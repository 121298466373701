@import './../../common.scss';

.section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.6vh 2vw;
  padding-right: 0;
  // Side bar width + section own width
  margin-left: calc(50px + 2rem);
  .header {
    font-size: 14px;
    display: flex;
    height: $headerHeight;
    align-items: center;
    margin: 1vh 0 2.4vh 0;
    justify-content: space-between;
    .navigation {
      display: flex;
      font-family: 'Red Hat Display';
      height: 100%;
      align-items: center;
      background-color: #f8f8fa;
      border: 1px solid #b2adad;
      border-radius: 2vw;
      z-index: 30;
      cursor: default;
      position: relative;
      span {
        min-width: 6vw;
      }

      .hdrNav {
        cursor: pointer;
        &:hover {
          font-weight: 500;
        }
        &.active {
          font-weight: 500;
        }
      }
      .actionDropdown {
        top: 106%;
        left: 1.6vw;
        position: absolute;
        background-color: #e8eaf1;
        height: 10vh;
        min-width: 8vw;
        padding: 1vh 1vw;
        font-size: 14px;
        z-index: 40;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: start;
        span {
          cursor: pointer;
        }
      }
    }
    //DocName is the name of the plant on top of page
    .docName {
      font-family: Open Sans;
      font-size: 15.5px;
      letter-spacing: -2%;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 1.1rem 2rem 1.1rem 1rem;
      background-color: #f4f4f4;
      border-radius: 2vw;
      z-index: 10;
    }
    .settingsMenu {
      position: absolute;
      top: 3rem;
      right: 7.4rem;
      width: 140px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      background: #fbfbfb;
      border: 1px solid #dfdddd;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px 0px 12px 12px;
      z-index: 100;
      span {
        cursor: pointer;
      }
    }
    .languageMenu {
      @extend .settingsMenu;
      top: 5.4rem;
      right: 26.7rem;
      height: 180px;

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .preferencesMenu {
    position: absolute;
    top: 4.2rem;
    right: 17rem;
    width: 140px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: #fbfbfb;
    border: 1px solid #dfdddd;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px 0px 12px 12px;
    z-index: 100;
    span {
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    flex-grow: 1;
    .canvas {
      display: flex;
      min-height: 88vh;
      width: 100%;
    }
  }
}
.preferencesSettings {
  position: absolute;
  cursor: pointer;
  top: 3.3vh;
  right: 5rem;
}
