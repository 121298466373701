@import './../../../common.scss';
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 6px;
  margin-top: 3rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #fafafa;
  min-width: $dragBarWidth;
  height: fit-content;
  padding: 1vh 0;

  .dragCategoryCont {
    position: relative;
    width: 100%;

    .dragCategory {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 8vh;
      cursor: pointer;
      font-family: 'Red Hat Display';
      .rect {
        display: flex;
        width: 3vw;
        height: 4vh;
      }
      .text {
        display: flex;
        width: 3vw;
        height: 1.4vh;
      }
      .lineHeight {
        line-height: 1;
      }

      .graph {
        display: flex;
        width: 3vw;
        height: 3.2vh;
      }
      .bin {
        display: flex;
        width: 3vw;
        height: 3.2vh;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }
    .draggableItems {
      background-color: #fafafa;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      position: absolute;
      padding: 3vh 0.6vw;
      width: calc(#{$dragBarWidth} * 2.6);
      min-height: 58vh;
      transform: translate(-102%, 0px);
      text-align: left;
      left: 0;
      top: 0;

      cursor: default;

      .dragItem {
        margin-bottom: 0.4vh;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 0.5vw;
      }

      .equipment {
        margin-bottom: 0.6vh;
        span {
          cursor: pointer;
        }
      }

      .loader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
