#infra_popup {
  position: fixed;
  z-index: 3;
  width: 570px;
  height: 300px;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  background: #fbfbfb;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  font-family: Nunito;
  display: none;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 4px 0px #00000040;
}

#process_popup,
#pump_popup {
  display: none;
}

#infra_popup_top {
  text-align: center;
  padding-top: 10px;
  padding-left: 117px;
  display: inline-block;
}

.infra_screen_select {
  height: 30px;
  width: 100px;
  line-height: 30px;
  border-radius: 5px;
  border-color: #e3e3e3;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  text-align: center;
}

.infra_screen_select:hover {
  background-color: #f0f0f0;
}

.selected_screen {
  border-color: #1a4996;
}

/*****************************************************/

#infra_values_cont {
  /* overflow-y: scroll; */
  /* height: 380px; */
  /* overflow-x: hidden; */
  width: 100%;
  /* padding-right: 17px; */
  margin-top: 20px;
  display: block;
}

#infra_values_cont h4 {
  font-size: 14px;
  padding-left: 40px;
  font-weight: normal;
}

#infra_values_cont h4:first-child {
  margin-top: 0px;
}

#infra_values_cont h4:last-child {
  margin-top: 10px;
}

#infra_values_cont table {
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  border-collapse: collapse;
}

#infra_values_cont table tr:first-child td {
  border-bottom: solid;
  border-color: black;
  width: 70px;
}

/************************************************************/

#pump_values_cont {
  height: 380px;
  /* width: 550px; */
  padding-right: 17px;
  margin-top: 20px;
  /* display: hidden; */
}

.pump_block {
  background-color: white;
  border-radius: 4px;
  border: 1.5px solid rgb(150, 150, 150);
  width: 400px;
  /* margin-left: 4%; */
  margin-bottom: 10px;
}

.pump_header {
  margin: auto 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  font-size: 17px;
}

.pump_block table {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-collapse: collapse;
}

.pump_block tr {
  text-align: center;
  padding: 0px 10px;
}

.pump_specs_input {
  border: none;
  background-color: rgb(230, 230, 240);
  width: 50px;
  border-radius: 5px;
  text-align: center;
}

#pump_streams_in_cont tr:first-child,
#pump_streams_out_cont tr:first-child {
  font-size: 15px;
}

#pump_streams_in_cont tr,
#pump_streams_out_cont tr {
  text-align: center;
  font-size: 13px;
}

.left_border {
  border-left: black solid 1px;
}

.pump_specs_output {
  width: 95px;
}

.red_text {
  color: red;
}

/***********************************************************/

#infra_details_cont {
  width: 550px;
  margin: 20px 0;
}

#infra_details_cont table {
  text-align: center;
  padding-left: 15px;
}

#infra_details_cont table th {
  vertical-align: top;
  padding-top: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.infra_name_input {
  padding-left: 15px;
  width: 240px;
  height: 27px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #0041e8;
  font-size: 14px;
  align-items: center;
  font-weight: 300;
  border: unset;
  padding-left: 40px;
  /* ::placeholder {
      color: #656565;
      font-family: Open Sans;
      line-height: 19px;
    }
    &.trigger-input {
      border-radius: 5px;
      text-align: center;
      padding-left: 0;
  } */
}

#infra_type_input {
  background: none;
  border: none;
  padding-left: 15px;
  width: 215px;
}

#add_spec_button {
  margin: 0 auto;
  width: 20px;
}

.infra_details_box {
  width: 200px;
  /* background-color: #e3e3e3; 
  border-radius: 5px;
  height: 25px;
  line-height: 25px;
  text-align: left;
  overflow: hidden; */
}

.infra_detail {
  background: none;
  border: none;
  padding-left: 15px;
  float: left;
  height: 20px;
  overflow-wrap: break-word;
  overflow: hidden;
  resize: none;
}

th {
  white-space: nowrap;
}

/* td svg {
    margin-right: 5px;
    float: right;
    overflow: visible;
} */

.hidden {
  visibility: hidden;
  font-size: 30px;
}
