.main-content {
  position: relative;
  top: -11px;
  background-color: #fbfbfb;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1.3px solid #d1d1d1;
  border-radius: 0px 0px 15px 15px;
  border-top: unset;
  box-shadow: 2px 4px 4px rgba(162, 162, 162, 0.25);
  width: 100%;

  .actionsSection {
    position: absolute;
    right: 2rem;
    display: flex;
    flex-direction: column;
    svg {
      width: 1rem;
      height: 1rem;
    }
    div {
      width: 12rem;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      cursor: pointer;
      span {
        margin-left: 1rem;
        font-size: 17px;
        font-family: 'Red Hat Display';
      }
    }
  }

  .ant-select {
    background-color: #ffffff;
    outline: none;
  }

  .ant-select-arrow {
    svg {
      height: 9px;
      width: 10px;
    }
    right: 5px;
    color: black;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-20 {
    margin-top: 25px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .padding-top-10 {
    padding-top: 10px;
  }
  .marginRight {
    margin-right: 3px;
  }
  .width-100 {
    width: 100%;
  }
  .width-438 {
    width: 438px;
  }
  .pl-30 {
    padding-left: 3%;
  }
  .input {
    height: 33px;
    background: #ffffff;
    mix-blend-mode: normal;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #0041e8;
    font-size: 14px;
    align-items: center;
    font-weight: 300;
    border: unset;
    padding-left: 40px;
    outline: none;
    ::placeholder {
      color: #656565;
      font-family: Open Sans;
      line-height: 19px;
    }
    &.trigger-input {
      border-radius: 5px;
      text-align: center;
      padding-left: 0;
    }
  }

  .members-list {
    position: absolute;
    width: 97%;
    height: 116px;
    background: #fcfcfc;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  .color-blue {
    color: #0041e8;
    font-size: 16px;
    font-weight: 300;
  }
  .member-content {
    width: 100%;
    font-size: 16px !important;
    padding: 12px 8px 0 8px;
    .center-div2 {
      z-index: 0;
      color: #656565;
      font-family: Open Sans;
      margin-top: 35px;
      font-size: 14px !important;
      font-weight: 300;
    }
  }
  .shareothers {
    background: #fbfbfb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding-left: 22px;
    height: 33px;
    color: #0041e8;
    font-size: 14px;
    ::placeholder {
      font-size: 14px !important;
      color: #656565;
    }
    &:focus {
      background-color: unset;
    }
  }
  .label-content {
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    margin-bottom: 25px;
  }
  .label-title2 {
    font-size: 17px;
    line-height: 23px;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .members-list2 {
    width: 100%;
    height: 115px;
    overflow: visible;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f1f1f1;
    height: 50%;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    margin-left: 15px;
    position: absolute;
    right: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
  }

  .padding-top-5 {
    padding-top: 5px;
  }
  .title-label {
    font-family: RED Hat Display;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: #000000;
    text-align: left;
    padding-left: 20%;
    margin-bottom: unset;
  }

  .item-select {
    text-align: left;
    width: 100%;
    height: 33px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #000000;
    font-family: Open Sans;
    font-weight: 300 !important;
    font-size: 16px;
    line-height: 22px;
    .ant-select-selector {
      padding-left: 15px;
      border: unset;
      background-color: unset;
    }

    .ant-select-selection-placeholder {
      color: #656565;
      font-family: Open Sans;
      font-size: 14px;
    }
  }
  .checkBox {
    margin-bottom: 10px;
  }
  .feedback-message {
    resize: none;
    height: 63px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: unset;
    color: #0041e8;
    padding-left: 20px;
    ::placeholder {
      color: #656565;
      line-height: 19px;
      font-size: 14px !important;
    }
    :focus-visible {
      border: unset !important;
    }
  }

  .tips {
    color: #3e3e3e;
    font-size: 15px;
    font-weight: 300;

    .tips-content {
      color: #3e3e3e;
      font-size: 14px;
    }
    .red {
      color: #994d4d;
    }
  }
  .align-text-start {
    text-align: start;
  }
}

.inner-label {
  font-family: Open sans;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  text-align: left;
}
