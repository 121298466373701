.DropBox {
  min-width: 150px;
  min-height: 33px;
  overflow-y: auto;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  background-color: white;
  font-family: Open Sans;
  font-weight: 300;
  padding: 4.5px 10px;
  font-size: 16px !important;
  position: relative;
  z-index: 10;

  &::-webkit-scrollbar-track
  {
    // -webkit-box-shadow: inset 0 0 6px  #9E9E9E;
    border-radius: 5px;
    background-color: #e8eaf1;
  }

  &::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    // -webkit-box-shadow: inset 0 0 6px  #9E9E9E;
    background-color: #9E9E9E;
  }

  .titleDiv {
    text-overflow: ellipsis;
  }
  .contentDiv{
    //display: none;
    font-size: 16px;
    width: 100%;
  }
  .title {
    //width: 170px;
    vertical-align: sub;
    font-size: 16px;
    text-align: center;
    padding-left: 33px;
    color: #0041E8;;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .right-arrow{
    position: absolute;
    right: 5px;
  }
}
.DropBox:hover {
  .contentDiv {
    display: inline-block;
  }
}
