.preferencesSettings {
  position: absolute;
  cursor: pointer;
  top: 1.85rem;
  right: 5rem;
  z-index: 101;
}
.settingsMenu {
  position: absolute;
  padding-right: 4rem;
  top: 1.55rem;
  right: 4.6rem;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: #f5f5f5;
  border: 0.5px solid #dfdddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  z-index: 100;
  span {
    cursor: pointer;
  }
}

.referenceValues {
  position: absolute;
  padding-right: 3rem;
  top: 0rem;
  right: 0rem;
  width: 480px;
  height: 38px;
  span {
    cursor: pointer;
  }
}

.preferencesMenu {
  position: absolute;
  top: 3.95rem;
  right: 14rem;
  width: 134px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-evenly;
  background: #f5f5f5;
  border: 0px;
  z-index: 100;
  cursor: pointer;

  &:hover {
    background-color: #ced1dd;
  }

  .rotateLeft {
    transform: rotate(90deg);
  }
  svg {
    width: 30px;
  }
}

.languageMenu {
  position: absolute;
  top: 4.7rem;
  right: 22.4rem;
  width: 134px;
  height: 265px;
  background: #f5f5f5;
  border-radius: 0px 0px 0px 12px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  align-items: flex-start;
  overflow: auto;

  button {
    display: flex;
    width: 100%;
    padding: 0.4rem;
    padding-left: 14px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: #ced1dd;
    }
  }
}
.menuOptions {
  margin-right: 1rem;
  margin-left: 2rem;
}
