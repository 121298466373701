@import './../../../common.scss';

.bar {
  font-size: 14px;
  opacity: 10;
  display: flex;
  height: $headerHeight;
  display: flex;
  font-family: 'Red Hat Display';
  height: 100%;
  align-items: center;
  background-color: white;
  border: 1px solid #b2adad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5vw;
  z-index: 30;
  cursor: default;
  position: relative;
  padding: 0.3rem;

  .subBar {
    position: absolute;
    top: 2.2rem;
    left: 1rem;
  }

  .item {
    margin: 0 8px;
    cursor: pointer;
    &:hover {
      font-weight: 500;
    }
    &.active {
      font-weight: 500;
    }
  }
}
