.chartContainer {
  height: 100%;
  width: 100%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #d6d6d6;

  .actions {
    height: 10%;
    position: relative;
    .settings {
      .settingsSvg {
        cursor: pointer;
        position: absolute;
        top: 4px;
        left: 6px;
        z-index: 2;
      }
      .settingsBar {
        position: absolute;
        top: -2px;
        left: 0px;
        z-index: 1;
      }
    }

    .refresh {
      position: absolute;
      top: 4px;
      right: 6px;
      cursor: pointer;
      z-index: 1;
    }
  }

  .chart {
    display: flex;
    height: 90%;
  }

  .spinner {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
