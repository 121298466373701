@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;

  .logo {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 50px;
    top: 30px;
    .chemeLogo {
      width: 70px;
      height: 30px;
    }
  }
}

.upperSection {
  width: 100%;
  height: 80%;
  background-image: url('./../../assets/img/home_background.png');
  background-position: bottom left;
  background-size: cover;
  background-repeat: no-repeat;

  .innerUpper_Part {
    width: 100%;
    height: 35%;

    .textContent {
      display: flex;
      flex-direction: column;
      padding-top: 120px;
      margin-left: 10vw;
      .firstLetter {
        font-family: Sen;
        font-size: 82px;
        letter-spacing: -3%;
        color: #ffffff;
        font-weight: normal;
      }
      h3 {
        text-align: start;
        font-family: Sen;
        font-size: 75px;
        letter-spacing: -3%;
        // margin-left: 7rem;
        color: #ffffff;
        font-weight: normal;
      }
      .lowerText {
        display: flex;
        flex-direction: column;

        .withMargin {
          margin-top: 30px;
          margin-left: 200px;
        }
        .withMarginExtended {
          margin-top: 30px;
          margin-left: 200px;
        }
      }
      .and {
        font-size: 15px;
      }
      p {
        font-family: 'Montserrat';
        text-align: start;
        font-size: 20px;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }

  .innerLower_Part {
    width: 100%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 18vh;
    p {
      font-family: SFProDisplayThin;
      font-size: 24px;
      /* font-weight: 400; */
      color: #ffffff;
      /* border: 2px solid black; */
      margin-bottom: 0.2 * 15rem;
    }

    button {
      border-radius: 45px;
      background-color: #ffffff;
      border: none;
      width: 225px;
      height: 33px;
      font-size: 24px; /*  */
      /* font-weight: 400; */
      margin-left: 0.5rem;
      color: #7b7b7b;
      outline: none;
      font-family: SFProDisplay;
    }

    button * {
      color: #7b7b7b;
      text-decoration: none;
    }
  }
}

.lowerSection {
  width: 100%;
  height: 20rem;
}
// .iconsAndInfo {
//   height: 150%;
// }
.lowerSection .iconsAndInfo {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: space-evenly;
  align-items: center;
  height: 60.5%;
  // padding-left: 7rem;
  // padding-right: 7rem;

  .imgWrapper {
    height: 42px;
    width: 50px;

    svg {
      height: 100%;
      width: 100%;
      // margin-left: -100px;
    }
    img {
      height: 100%;
    }
  }
}

.lowerSection .footer {
  width: 100%;
  height: 18%;
  padding-left: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 400;
  background-image: url('./../../assets/img/footer_background.png');
  background-position: bottom left;
  background-size: cover;
  background-repeat: no-repeat;
}
.lowerSection .footer p {
  color: #ffffff;
  margin-bottom: 0;
}

.clickToLogin {
  display: flex;
  align-items: center;
  font-family: SFProDisplay;
  font-size: 24px;
  .loginGuid {
    color: white;
  }
  .loginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    height: 33px;
    width: 81px;
    margin-left: 1rem;
    color: #7b7b7b;
    cursor: pointer;
  }
}

.lowerSection .iconsAndInfo {
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 420px;
  padding: 2rem 0 2rem 5rem;
  .menu {
    width: 100%;
    font-size: 19px;
    display: flex;
    align-items: center;
    font-family: SFProDisplayLight;
    cursor: pointer;
    letter-spacing: 1px;

    span {
      margin-left: 2.6rem;
      width: 100%;
      width: fit-content;
      &.active {
        font-weight: 500;
        margin-left: 2.8rem;
        font-size: 21px;
        font-family: 'SFProDisplay';
      }
    }
  }
}

.lowerSection1 {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 85%;
}
.lowerSection1b {
  display: flex;
  flex-direction: column;
  height: 84%;
  width: 50%;
  align-items: flex-start;
  margin-top: 1.2rem;
  margin-left: 4rem;
  padding-left: 3.5rem;
  padding-top: 1rem;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 30px;
  border-right: 0;
  border-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  font-family: SFProDisplayLight;
  font-size: 19px;
  font-weight: 300;

  .menu_options {
    margin-top: 1.2rem;
    display: flex;
    svg {
      margin-right: 1.2rem;
    }
  }
}
