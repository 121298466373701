.design {
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 5px;
  background: #fbfbfb;
  width: 150px;

  top: 0px;
  right: -22px;

  .dropDown {
    margin-top: 2px;
    margin-left: 24px;
    width: 100px;
    font-family: 'Red Hat Display';
    height: 100%;
    align-items: center;
    background-color: #f8f8fa;
    cursor: pointer;
    background: #fafafd;
    border: 1px solid #9c9797;
    box-sizing: border-box;
    border-radius: 6px;
    z-index: 30;
    position: relative;
  }

  .line {
    width: 125px;
    margin-left: 13px;
  }

  .color {
    margin-top: -15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .dropDownList {
    display: flex;
    flex-direction: column;
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 8px 8px 8px;
    background: #fbfbfb;
    width: 100px;
    top: 52px;
    right: 25px;
    z-index: 31;
  }
  .colorPanel {
    position: absolute;
    top: 116px;
    right: -35px;
    z-index: 40;
  }
  .selectedColor {
    width: 1.58vw;
    height: 1.2vw;
    margin-top: 5px;
    margin-left: 3px;
    background-color: black;
  }
  .colorSection {
    display: flex;
    flex-direction: row;
  }
}
