.table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 22px 22px 22px 50px;
  .content {
    margin-top: 15px;
    width: 100%;
  }
  .addButton {
    margin-left: 15px;
    font-size: 15px;
    margin-top: -10px;
    margin-right: 2%;
    cursor: pointer;
  }
  .tab {
    font-family: Red Hat Display;
    font-size: 16.5px;
    padding: 0 10px;
    margin-left: 50px;
    color: #616161;
    background: #f8f8fa;
    border: 1px solid #dadada;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);//
    border-radius: 30px;
    .tab-text {
      cursor: pointer;
      padding: 7px 13px;
      color: #616161;
      &.active {
        font-size: 17px;
        font-weight: 400;
        color: #000000 !important;
      }
    }
  }
  .table-div {
    tr {
      border-bottom: 1pt solid black;
    }
    margin-top: 10px;
    padding-right: 33px;

    .historyTable {
      tr {
        td:nth-child(1) {
          background-color: #ffffff;
          width: 1px;
          padding: 0 20px;
        }

        td:nth-child(2) {
          width: 168px;
        }

        td:nth-child(3) {
          width: 276px;
        }

        td:nth-child(4) {
          width: 120px;
        }

        td:nth-child(5) {
          width: 120px;
        }

        td:nth-child(6) {
          width: 200px;
        }

        td:nth-child(7) {
          width: 84px;
        }
      }

      tr:hover {
        td:nth-child(4) {
          text-decoration: underline;
        }

        td:nth-child(5) {
          text-decoration: underline;
        }
        height: 43px !important;
      }
      table {
        tr {
          height: 42px !important;
          td {
            height: 30px !important;
          }
          th {
            height: 30px !important;
          }
        }
      }
    }

    .teamTable {
      tr {
        td:nth-child(1) {
          background-color: #ffffff;
          width: 13px;
          padding: 0 24px;
        }

        td:nth-child(2) {
          width: 381px;
        }

        td:nth-child(3) {
          width: 406px;
        }

        td:nth-child(4) {
          width: 270px;
          color: #0041e8;
        }

        td:nth-child(5) {
          width: 140px;
          min-width: 140px;
          span:first-child:not(.expand-icon) {
            @media (max-width: 1600px) {
              margin-right: 28px;
            }
          }
        }
      }

      tr:hover {
        td:nth-child(4) {
          text-decoration: underline;
          text-decoration-color: #0041e8 !important;
        }

        td:nth-child(5) {
          text-decoration: underline;
        }
      }
    }
    .menu-button {
      padding: 0 46px;
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: #000000;
      margin-bottom: 16px !important;
    }
  }
  .settings-icon {
    height: 1rem;
  }
}

.ant-popover-placement-bottomLeft {
  padding-top: unset !important;
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner {
    border-radius: 0px 12px 12px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    border: 1px solid #dfdddd;
    box-sizing: border-box;
    .menu-item {
      padding: 0 18px;
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      margin-bottom: 16px !important;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      color: #000000;
    }
    .menu-button {
      padding: 0 46px;
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: #000000;
      margin-bottom: 16px !important;
    }
  }
}
.ant-popover-placement-bottomRight {
  padding-top: unset !important;
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner {
    border-radius: 12px 0px 12px 12px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    background: #ffffff !important;
    border: 1px solid #dfdddd !important;
    box-sizing: border-box !important;
    margin-right: 30px !important;
    .menu-item {
      padding: 0 32px !important;
      font-family: Open Sans, serif !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 22px !important;
      display: flex !important;
      align-items: center !important;
      margin-bottom: 14px !important;
      text-align: center !important;
      justify-content: center !important;
      cursor: pointer !important;
      color: #000000 !important;
    }
  }
}
