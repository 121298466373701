#stream_popup {
  position: fixed;
  z-index: 3;
  width: 550px;
  height: 300px;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  background: #fbfbfb;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  font-family: Nunito;
  display: none;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 4px 0px #00000040;
}

#stream_popup_top {
  text-align: center;
  padding-top: 10px;
  padding-left: 90px;
  display: inline-block;
}

.stream_screen_select {
  height: 30px;
  width: 100px;
  line-height: 30px;
  border-radius: 5px;
  border-color: #e3e3e3;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  text-align: center;
}

.stream_screen_select:hover {
  background-color: #f0f0f0;
}

.selected_screen {
  border-color: #1a4996;
}

/* Values section ************************************************************/

#stream_values_cont {
  margin-top: 10px;
  display: block;
}
#stream_name {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: blue;
}

#stream_values_table {
  table-layout: fixed;
  font-size: 15px;
  margin: 0 auto;
  border-collapse: collapse;
  text-align: center;
}

#stream_values_table th {
  background-color: #c9cbd5;
  height: 25px;
  line-height: 25px;
  font-weight: normal;
}

#stream_values_table th:first-child {
  border-radius: 5px 0 0 5px;
}

#stream_values_table th:last-child {
  border-radius: 0 5px 5px 0;
}

#stream_values_table input {
  width: 80px;
  background: none;
  text-align: center;
}

#stream_diagram {
  margin: 0 auto 20px auto;
  text-align: center;
}
.red_underline {
  border-bottom-color: red;
  border-left: none;
  border-right: none;
  border-top: none;
}

.no_border {
  border: none;
}

/* Details Section **********************************************************/

#stream_details_cont {
  /* display: none; */
}

#stream_details_table {
  margin: 0 auto;
  margin-bottom: 20px;
  top: var(--toolbar-top);
}

#stream_details_table td {
  text-align: left;
  height: 25px;
  line-height: 25px;
  padding-right: 10px;
}

#stream_details_table th {
  padding-right: 10px;
  text-align: right;
  font-weight: normal;
}

.row_with_height {
  height: 60px;
}

#stream_name_input {
  padding-left: 15px;
  height: 27px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #0041e8;
  font-size: 14px;
  align-items: center;
  font-weight: 300;
  border: unset;
}

#disp_name {
  font-size: 10px;
  height: 25px;
  line-height: 25px;
}

#disp_name input {
  height: 10px;
}

.select {
  width: 100%;
  height: 25px;
  padding-left: 10px;
  text-align: left !important;
  /* padding-right: 20px; */
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #0041e8;
  font-family: Open Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  border: 0;
  /* .ant-select-selector {
      padding-left: 20px;
      border: unset;
      background-color: unset;
    }
    ::placeholder {
      color: #656565;
      line-height: 19px;
    } */
}
