.Table2 {
  tr {
    border: 1px solid red;
  }
  .first-row-unset {
    tr:first-child {
      td:first-child {
        border-bottom-left-radius: unset !important;
        border-top-left-radius: unset !important;
      }
      td {
        height: 37px;
        padding: unset !important;
        font-size: unset !important;
        background-color: unset !important;
        margin-left: 2px;
      }
      td:last-child {
        border-bottom-right-radius: unset !important;
        border-top-right-radius: unset !important;
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 2.5px 12px;
    table tr td:first-child {
      border-left: 0;
    }

    table tr td:last-child {
      border-right: 0;
    }
  }
  th {
    font-family: Red Hat Display;
    font-size: 17px;
    text-align: center;
    font-weight: normal;
  }
  td {
    font-family: Open Sans;
    border-top-style: none !important;
    border-left-style: none !important;
    border-right-style: none !important;
    border-bottom-style: none !important;
  }
  tr {
    text-align: center;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .table thead th {
    vertical-align: unset;
    border-bottom: unset;
    border-top: unset;
  }
  tbody {
    tr:hover,
    .selected {
      text-decoration: underline;
      text-decoration-color: inherit;
      .delete-icon {
        filter: invert(15%) sepia(72%) saturate(3684%) hue-rotate(355deg)
          brightness(101%) contrast(88%);
      }
      //zoom: 1.1;
      height: 43px;
      td {
        border-top: 1.3px solid #d1d1d1 !important;
        border-bottom: 1.3px solid #d1d1d1 !important;
        box-shadow: 2px 4px 4px rgba(162, 162, 162, 0.25);
        
      }
      td:nth-child(2) {
        border-left: 1.3px solid #d1d1d1 !important;
      }
      td:last-child {
        border-right: 1.3px solid #d1d1d1 !important;
      }
      td:first-child {
        border: none !important;
        box-shadow: unset !important;
      }
      .expansion-icon {
        display: block;
        padding-top: 5px;
        .expand-icon {
          content: url('../../../assets/svg/Expansion-black.svg') !important;
          width: 16px;
          height: 16px;
        }
      }
      td.expansion-panel {
        text-decoration: none;
      }
    }
  }
  .expansion-tr {
    cursor: default;
  }

  .expansion-tr:hover {
    text-decoration: none;
    td {
      border-top: unset !important;
      border-bottom: unset !important;
      box-shadow: unset !important;
    }
    td:nth-child(2) {
      border-left: unset !important;
    }
    td:last-child {
      border-right: unset !important;
    }
  }
  tr {
    .expansion-panel {
      padding: 0 8px !important;
      background-color: unset;
      margin: unset;
      position: relative;

      .main-div {
        border: 1.3px solid #d1d1d1;
      }
    }
    height: 42px;
    td:nth-child(2) {
      border-bottom-left-radius: 15px !important;
      border-top-left-radius: 15px !important;
    }
    td {
      padding: unset;
      height: 42px;
      vertical-align: middle;
      font-family: Open Sans;
      font-size: 16px;
      background-color: #e7ffe8;
      margin-left: 2px;
    }
    .expansion-icon {
      padding-top: 4px;
      .expand-icon {
        content: url('../../../assets/svg/Expansion.svg') !important;
      }
      right: 16px;
      top: -2px;
    }
    td:last-child {
      border-bottom-right-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }
  }
}
.menu-item-delete {
  padding: 17px 21px;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 16px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  color: #ff0000;
}
.menu-item-delete {
  padding: 17px 21px;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 16px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  color: #ff0000;
}
.noHeaderMsg {
  font-size: 1rem;
  margin: 12rem;
  margin-left: 17.5rem;
}