.equationInput {
  display: flex;
  border: 1px solid #aab7b8;
  height: 33px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* color: #0041e8; */
  font-size: 14px;
  align-items: center;
  font-weight: 300;
  border: unset;
  padding-left: 1rem;
  outline: none;
  position: relative;
  .leftSide {
    color: #0041e8;
    display: flex;
    .name {
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .equal {
      margin: 1px 0 0 4px;
    }
  }

  .inputSpan {
    display: flex;
    flex-grow: 1;
    align-items: center;
    outline: none;
    cursor: auto;
    text-align: start;
    margin: 0 1rem;
    overflow: hidden;
    max-width: 40rem;
    white-space: nowrap;
    &::before {
      content: '\feff';
    }
  }

  .list {
    position: absolute;
    z-index: -1;
    .hideSelector {
      opacity: 0;
      // width: 0.001px;
    }
  }
  .selectedItemNode {
    background: #f1faff;
    border: 1px solid #0073bb;
    border-radius: 2px;
    padding: 2px 4px;
    transition: padding-right 0.1s ease;
    height: 24px;
    align-items: center;

    &:hover {
      .cancel {
        opacity: 100;
        width: 0.6rem;
        cursor: pointer;
      }
    }

    .cancel {
      opacity: 0;
      height: 0.6rem;
      width: 0;
      margin-left: 0.3rem;
      transition: opacity 0.2s ease 0.2s, width 0.2s ease;
    }
  }
}
