@import './../../../common.scss';

.section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border-top: 1px solid #cecece;
  border-left: 1px solid #cecece;
  border-top-left-radius: 1.2vw;
  position: relative;
  .canvasAction {
    position: absolute;
    right: 0;
    transform: translate(0, -120%);
    display: flex;
    align-self: center;
    .save {
      color: #158e01;
      display: flex;
      align-items: center;
      svg {
        width: 1vw;
        height: 2vh;
        margin-right: 0.8vw;
      }
      margin-right: 3vw;
    }
    .cancel {
      color: #a50a00;
      display: flex;
      align-items: center;
      svg {
        width: 1vw;
        height: 1vh;
        margin-right: 0.8vw;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: -0.1rem;
    cursor: default;
    z-index: 15;

    .headerItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #b1aeae;
      border-radius: 15px;
      background-color: white;
      padding: 0.4rem 1.4rem;
      cursor: pointer;

      // adjust Width of Canvas title Tab
      span {
        width: 6.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.active {
        position: relative;
        border: 1px solid #b1aeae;
        font-weight: 500;
        background: #f6f6f6;
        box-sizing: border-box;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
      }
      &:hover {
        padding-right: 0.97rem;
        padding-left: 1rem;
        .editIcon {
          display: flex;
        }
      }
      &.renaming {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
      }
      &.menuActive {
        cursor: default;
        padding-right: 0.97rem;
        padding-left: 1rem;
        .editIcon {
          display: flex;
        }
      }
      .editIcon {
        display: none;
        cursor: pointer;
      }
      .canvasMenu {
        display: flex;
        position: absolute;
        top: 2.7rem;
        left: 2rem;
        width: 7vw;
        height: 10vh;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background: #fbfbfb;
        border: 1px solid #dfdddd;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px 12px 12px 12px;
        z-index: 100;
        span {
          cursor: pointer;
        }
      }
    }
    .addPage {
      margin-left: -2vw;
      color: #b6b6b6;
      z-index: 10;
    }
    .canvasTitleSection {
      display: flex;
      align-items: center;

      input {
        width: 6rem;
        height: 1.3rem;
      }
      svg {
        cursor: pointer;
        margin: 0 0.2rem;
      }
    }
  }

  .emptyPage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
    justify-content: center;

    span {
      margin-bottom: 1vh;
    }

    .instrBtm {
      display: flex;
      flex-direction: column;
      margin-top: 2vh;
      span {
        margin-bottom: 2vh;
        color: #7e7e7e;
      }
    }
  }
  .canvasBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;

    .graphContainer {
      width: 100%;
      height: 100%;
    }

    .dragArea {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      position: fixed;
    }
  }

  .plus {
    cursor: pointer;
    margin-left: 0.3rem;
  }
}

.canvasLowerBar {
  background-color: #f2f2f2;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .lowerBarText {
    margin-left: 0.5rem;
    font-family: Open Sans;
  }
}
