.sidenav {
  z-index: 30;
  background-color: #849cb8;
  overflow-x: hidden;
  font-size: 15px;
  text-align: center;
  height: 100vh;
  overflow-y: hidden;
  width: 60px;
  position: fixed;
  top: 0;
  bottom: 0;
  /* transition: all 0.1s ease !important; */
}
.section1 {
  height: 14%;
}
.section2 {
  height: 40%;
}
.section3 {
  height: 40%;
}

.sidenav a {
  font-family: 'Red Hat Display', sans-serif;
  text-decoration: none;
  line-height: 18px;
  color: #f1f1f1;
}
.demo {
  height: 44px;
  transition: all 0.15s ease;
  font-size: 15px;
  font-weight: 400;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &:hover {
      color: #f1f1f1;
      text-decoration: none;
    }
    &:active {
      color: #f1f1f1;
      background-color: #aa7781a1 !important;
    }
  }
}
.demo a:active {
  /* transition: all 0.1s ease; */
  background-color: #aa7781 !important;
}
.demo a:visited {
  /* transition: all 0.1s ease; */
  background-color: #aa7781 !important;
}

#mouse {
  width: 50px;
  /* transition: all 0.1s ease; */
}
/* #mouse:hover{
  width: 60px;
  } */

.big {
  width: 60px;
  transition: all 0.1s ease;
}

.demo:hover {
  /* background: rgba(191, 96, 96, 0.63); */
}

hr {
  height: 2px !important;
  background-color: #fff !important;
  margin-bottom: 1.2rem !important;
}

.profile_img_cont {
  text-align: center;
  padding: 10px;
}

.img1 {
  width: 25px;
  height: 26px;
}

.img2 {
  width: 26px;
  height: 30px;
}

.img3 {
  width: 25px;
  height: 26px;
}

.sidebarlink {
  cursor: pointer;
}
