.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  margin-bottom: 24px;
  width: 100%;

  .header {
    font-size: 15px;
    font-weight: 400;
    margin: 15px 0;
  }
}
