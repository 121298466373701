@import './../../../common.scss';

.header {
  font-size: 14px;
  display: flex;
  height: $headerHeight;
  .navigation {
    display: flex;
    font-family: 'Red Hat Display';
    height: 100%;
    align-items: center;
    background-color: #f8f8fa;
    border: 1px solid #b2adad;
    border-radius: 2vw;
    cursor: default;
    position: relative;

    .hdrNav {
      cursor: pointer;
      font-size: 15px;
      &:hover {
        font-weight: 500;
      }
      &.active {
        font-weight: 500;
      }
    }

    .timeBar {
      position: absolute;
      top: 32px;
      left: 25px;
    }

    .dataSection {
      position: absolute;
      top: 32px;
      right: 151px;
    }

    .designSection {
      position: absolute;
      top: 32px;
      right: -55px;
    }

    .settings {
      position: absolute;
      top: -20px;
      right: 20px;
    }
  }
}
