#simulate_popup {
  position: fixed;
  z-index: 3;
  width: 400px;
  height: 200px;
  top: 50%;
  margin-top: -100px;
  left: 50%;
  margin-left: -200px;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  font-family: Arial;
  display: none;
  overflow: hidden;
  text-align: center;
  background-color: #FBFBFB;
  mix-blend-mode: normal;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 16px;
}

#ask_permission {
  display: none;
}

#ask_message_head {
  margin: 0 auto;
  margin-bottom: 30px;
}

#ask_message {
  margin: 0 auto;
}

#permission_buttons {
  margin-top: 30px;
}

#keep_simulation,
#delete_simulation {
  margin: 0 20px;
  text-align: center;
  width: 70px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  cursor: pointer;
}

#keep_simulation:hover,
#delete_simulation:hover,
#close_sim_popup:hover {
  background-color: rgb(247, 245, 245);
}

#calculating {
  display: none;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-left: 25px;
  line-height: 150px;
}

#finished_calculating {
  display: block;
}

#sim_error_message {
  text-align: center;
  margin: 40px auto;
}

#close_sim_popup {
  text-align: center;
  border: solid;
  border-width: 1px;
  border-color: #6a757c;
  border-radius: 5px;
  background-color: white;
  width: 70px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  cursor: pointer;
}
