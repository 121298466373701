@import './../../../common.scss';

.section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // position: relative;
  padding: 1.6vh 2vw;
  padding-right: 0;
  height: 100vh;
  width: 100vw;
  // Side bar width + section own width
  margin-left: calc(50px + 2rem);
  font-family: 'Red Hat Display', sans-serif;
  text-align: initial;
  .simName {
    position: fixed;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0 60px;
    top: var(--toolbar-top);
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    background-color: #f4f4f4;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    line-height: 35px;
    color: black;
    border-radius: 25px;
    text-align: center;
    user-select: none;
  }
  .settingsNavigation {
    position: absolute;
    top: var(--toolbar-top);
    right: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 14px;
    z-index: 10;
  }
  .hide {
    display: none;
  }
  .popupCont {
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.hide {
  opacity: 0;
}

.canvas {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.languageMenu {
  position: absolute;
  top: 0.7rem;
  right: 10.8rem;
  width: 134px;
  height: 228px;
  background: #f5f5f5;
  border-radius: 0px 0px 0px 12px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  align-items: flex-start;
  overflow: auto;
  button {
    display: flex;
    width: 100%;
    padding: 0.1rem;
    padding-left: 14px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: #ced1dd;
    }
  }
}
